// import PropTypes from "prop-types"
import clsx from "clsx"
import ProductImageGallery from "../../components/product/ProductImageGallery"
import ProductDescriptionInfo from "../../components/product/ProductDescriptionInfo"
import ProductImageGallerySideThumb from "../../components/product/ProductImageGallerySideThumb"
import ProductImageFixed from "../../components/product/ProductImageFixed"
import { Spin } from "antd"

const ProductImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  galleryType,
  product,
  isLoading,
  creator = false,
}) => {
  return (
    <div className={clsx("shop-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
          spinning={isLoading}
        >
          <div className="row">
            <div className="col-lg-6 col-md-6">
              {galleryType === "leftThumb" ? (
                <ProductImageGallerySideThumb
                  product={!creator ? product : product?.isProduct}
                  thumbPosition="left"
                />
              ) : galleryType === "rightThumb" ? (
                <ProductImageGallerySideThumb
                  product={!creator ? product : product?.isProduct}
                />
              ) : galleryType === "fixedImage" ? (
                <ProductImageFixed
                  product={!creator ? product : product?.isProduct}
                />
              ) : (
                <ProductImageGallery
                  product={!creator ? product : product?.isProduct}
                  creator={creator}
                />
              )}
            </div>
            <div className="col-lg-6 col-md-6">
              <ProductDescriptionInfo
                product={!creator ? product : product?.isProduct}
                creator={creator}
              />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  )
}

// ProductImageDescription.propTypes = {
//   galleryType: PropTypes.string,
//   product: PropTypes.shape({}),
//   spaceBottomClass: PropTypes.string,
//   spaceTopClass: PropTypes.string,
//   creator: PropTypes.bool,
//   isLoading: PropTypes.bool,
// };

export default ProductImageDescription
