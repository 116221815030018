import React, { useState } from "react";
import styles from "./ModidfyProductDetail.module.css";
import { Collapse } from "antd";
import Prompt from "./Prompt";
import ModifyForm from "./ModifyForm";

function ModifyProductDetail({ data, size }) {
  const [selectedImageCol, setSelectedImageCol] = useState();
  const [generatedAllImages, setGeneratedAllImages] = useState();
  const [imagine, setImagine] = useState(null);


  return (
    <div className={styles.parentDiv}>
      <div className={styles.mainDiv}>
        <div
          className={`${styles.collapseDiv} collapseDiv`}
          style={{ minHeight: "60vh" }}
        >
          <Collapse
            defaultActiveKey={["1"]}
            expandIconPosition="end"
            items={[
              {
                key: "1",
                label: "1. DESIGN YOUR PRODUCT",
                children: (
                  <Prompt
                    data={data?.isProduct}
                    setSelectedImageCol={setSelectedImageCol}
                    selectedImageCol={selectedImageCol}
                    setGeneratedAllImages={setGeneratedAllImages}
                    imagine={imagine}
                    setImagine={setImagine}
                  />
                ),
              },
            ]}
          />
          <div className={styles.divider}></div>
          <Collapse
            expandIconPosition="end"
            items={[
              {
                key: "2",
                label: "2. MODIFY YOUR PRODUCT DETAILS",
                children: (
                  <ModifyForm
                    data={data}
                    size={size}
                    selectedImageCol={selectedImageCol}
                    generatedAllImages={generatedAllImages}
                    imagine={imagine}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default ModifyProductDetail;
