import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ModidfyPorduct from "./pages/modify-product-detail/modify-product-detail";
import Confirmation from "./pages/confirmation/Confirmation";
import { ProtectedRoute } from "./Services/protectedRoutes";
import ShopCustomer from "./pages/shop-customer/ShopCustomer";
import GeneratedProduct from "./wrappers/product/GeneratedProduct";

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + "/modify-product-detail"}
              element={
                <ProtectedRoute roles={["CREATOR"]}>
                  <ModidfyPorduct />
                </ProtectedRoute>
              }
            />
            {/* Confirmation Page */}
            <Route
              path={process.env.PUBLIC_URL + "/confirmation"}
              element={
                <ProtectedRoute roles={["CREATOR"]}>
                  <Confirmation />
                </ProtectedRoute>
              }
            />
            {/* Shop pages */}
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<ShopCustomer />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/creator"}
              element={
                <ProtectedRoute roles={["CREATOR"]}>
                  <ShopGridStandard />
                </ProtectedRoute>
              }
            />

            <Route
              path={process.env.PUBLIC_URL + "/product/:id"}
              element={<GeneratedProduct />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/creator/product/:id"}
              element={
                <ProtectedRoute roles={["CREATOR"]}>
                  <Product />
                </ProtectedRoute>
              }
            />

            {/* Shop product pages */}

            <Route
              path={
                process.env.PUBLIC_URL +
                "/:name_of_creator/:substrate/:collection/:name_of_product/:id"
              }
              element={<GeneratedProduct />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/login"}
              element={<LoginRegister />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
