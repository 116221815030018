import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import clsx from "clsx";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import MobileMenu from "../../components/header/MobileMenu";
import { Image } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
}) => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  const data = localStorage.getItem("artefax");
  const parseData = JSON.parse(data);

  // static state to handle the login and signup
  const [creator, setCreator] = useState(false);

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header
      className={clsx(
        "header-area clearfix",
        headerBgClass,
        headerPositionClass
      )}
    >
      <div
        className={clsx(
          "header-top-area",
          headerPaddingClass,
          top === "visible" ? "d-none d-lg-block" : "d-none",
          borderStyle === "fluid-border" && "border-none"
        )}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
          {/* header top */}
          {/* <HeaderTop borderStyle={borderStyle} /> */}
        </div>
      </div>

      <div
        className={clsx(
          headerPaddingClass,
          "sticky-bar header-res-padding clearfix",
          scroll > headerTop && "stick"
        )}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6 col-4">
              {/* header logo */}
              {/* <Logo imageUrl="/assets/img/logo/logo.png" logoClass="logo" /> */}
              <Logo imageUrl="/assets/img/footerlogo.png" logoClass="logo" />
            </div>
            <div className="col-xl-8 col-lg-8 d-none d-lg-block">
              {/* Nav menu */}
              <NavMenu />
            </div>
            <div
              className="col-xl-2 col-lg-2 col-md-6 col-8"
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {/* Icon group */}
              {/* <IconGroup /> */}
              {creator ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  Creator Name
                  <div>
                    <Image
                      preview={false}
                      alt=""
                      src="/assets/img/account.png"
                      style={{
                        height: "32px",
                        width: "32px",
                        marginLeft: "3px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "1px solid #363636",
                    padding: "10px 5px",
                    marginRight: "10px",
                  }}
                >
                  {parseData?.token ? (
                    <span
                      style={{
                        width: "80px",
                        textAlign: "center",
                        color: "#363636",
                      }}
                      onClick={() => {
                        localStorage.removeItem("artefax");
                        navigate("/");
                      }}
                    >
                      LOGOUT
                    </span>
                  ) : (
                    <span
                      style={{
                        width: "80px",
                        textAlign: "center",
                        color: "#363636",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      LOGIN
                    </span>
                  )}
                </div>
              )}

              <div className="same-style mobile-off-canvas d-block d-lg-none">
                <button
                  onClick={() => triggerMobileMenu()}
                  style={{ height: "45px", width: "45px", background: "#fff" }}
                >
                  <MenuOutlined rev={undefined} style={{ fontSize: "25px" }} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* mobile menu */}
        <MobileMenu />
      </div>
    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
