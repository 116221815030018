import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { getDiscountPrice } from "../../helpers/product";
import ProductModal from "./ProductModal";
import { Image } from "antd";
import { baseurl } from "../../utils/axios";

const ProductGridListSingle = ({
  product,
  currency,
  wishlistItem,
  compareItem,
  spaceBottomClass,
  creator = false,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const discountedPrice = getDiscountPrice(product.price, product.discount);
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <div className={clsx("product-wrap", spaceBottomClass)}>
        <div
          className="product-img"
          style={{
            display: "flex",
            justifyContent: "center",
            alignConten: "center",
            width: "271px",
            height: "371px",
            border: " 1px solid #F0F0F0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Link
              to={
                !creator
                  ? process.env.PUBLIC_URL + "/product/" + product.id
                  : process.env.PUBLIC_URL + "/creator/product/" + product.id
              }
            >
              <Image
                preview={false}
                className="default-img"
                src={
                  !creator
                    ? product?.images[0]
                    : `${baseurl}/${product?.File[0]?.url}`
                }
                width="100%"
                height="100%"
                alt="imge"
                style={{ objectFit: "cover" }}
              />
            </Link>
          </div>
        </div>
        <div className="product-content text-center">
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "271px",
            }}
          >
            <Link
              to={
                !creator
                  ? process.env.PUBLIC_URL + "/product/" + product.id
                  : process.env.PUBLIC_URL + "/creator/product/" + product.id
              }
            >
              {product.name}
            </Link>
          </h3>

          <div
            className="product-price"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "271px",
            }}
          >
            {discountedPrice !== null ? (
              <Fragment>
                <span style={{ color: "#FE5252" }}>
                  {"$" + finalDiscountedPrice}
                </span>{" "}
              </Fragment>
            ) : (
              <span style={{ color: "#FE5252" }}>
                {"$" + finalProductPrice}{" "}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="shop-list-wrap mb-30">
        <div className="row">
          <div className="col-xl-4 col-md-5 col-sm-6   ">
            <div className="product-list-image-wrap">
              <div
                className="product-img"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "271px",
                  height: "371px",
                  border: " 1px solid #F0F0F0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Link
                    to={
                      !creator
                        ? process.env.PUBLIC_URL + "/product/" + product.id
                        : process.env.PUBLIC_URL +
                          "/creator/product/" +
                          product.id
                    }
                  >
                    <Image
                      preview={false}
                      className="default-img"
                      src={
                        !creator
                          ? product.images[0]
                          : `${baseurl}/${product?.File[0]?.url}`
                      }
                      width="100%"
                      height="100%"
                      alt="imge"
                      style={{ objectFit: "cover" }}
                    />
                  </Link>
                </div>

                {product.discount || product.new ? (
                  <div className="product-img-badges">
                    {product.discount ? (
                      <span className="pink">-{product.discount}%</span>
                    ) : (
                      ""
                    )}
                    {product.new ? <span className="purple">New</span> : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-md-7 col-sm-6">
            <div className="shop-list-content">
              <h3>
                <Link
                  to={
                    !creator
                      ? process.env.PUBLIC_URL + "/product/" + product.id
                      : process.env.PUBLIC_URL +
                        "/creator/product/" +
                        product.id
                  }
                >
                  {product.name}
                </Link>
              </h3>
              <div className="product-list-price">
                {discountedPrice !== null ? (
                  <Fragment>
                    <span>{"$" + finalDiscountedPrice}</span>
                    <span className="old">{"$" + finalProductPrice}</span>
                  </Fragment>
                ) : (
                  <span>{"$" + finalProductPrice} </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedPrice={discountedPrice}
        finalProductPrice={finalProductPrice}
        finalDiscountedPrice={finalDiscountedPrice}
        wishlistItem={wishlistItem}
        compareItem={compareItem}
      />
    </div>
  );
};

ProductGridListSingle.propTypes = {
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.shape({}),
  creator: PropTypes.bool,
};

export default ProductGridListSingle;
