import { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { baseurl } from "../../utils/axios";
import { Image, Spin } from "antd";

const ProductGridSingle = ({
  product,
  spaceBottomClass,
  isLoading,
  creator = false,
}) => {
  const price = +product.price;

  return (
    <Fragment>
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
        spinning={isLoading}
      >
        <div
          className={clsx("product-wrap", spaceBottomClass)}
          style={{ border: "1px solid #F0F0F0" }}
        >
          <div className="product-img" style={{ height: "280px" }}>
            <Link
              to={
                !creator
                  ? process.env.PUBLIC_URL + "/product/" + product?.id
                  : process.env.PUBLIC_URL + "/creator/product/" + product?.id
              }
            >
              {(!creator && product?.images?.length !== 0) ||
              product?.File[0]?.url ? (
                <div>
                  <Image
                    preview={false}
                    className="default-img"
                    src={
                      !creator
                        ? `${product?.images[0]}`
                        : `${baseurl}/${product?.File[0]?.url}`
                    }
                    alt=""
                    style={{
                      maxHeight: "280px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  No Image
                </div>
              )}
            </Link>
          </div>
          <div className="product-content text-center">
            <h3>
              <Link
                to={
                  !creator
                    ? process.env.PUBLIC_URL + "/product/" + product.id
                    : process.env.PUBLIC_URL + "/creator/product/" + product.id
                }
              >
                {product.name}
              </Link>
            </h3>
            <div className="product-price">
              <span style={{ color: "red" }}>${`${price}`}</span>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

ProductGridSingle.propTypes = {
  product: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
};

export default ProductGridSingle;
