import PropTypes from "prop-types";
import { useState } from "react";

const ShopCategories = ({ categories, setCategory }) => {
  const [activeCategoryId, setActiveCategoryId] = useState("");

  const setCategoryQuery = ({ id }) => {
    setCategory(id);
    setActiveCategoryId(id);
  };

  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">Categories</h4>
      <div className="sidebar-widget-list mt-30">
        {categories ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                  className={activeCategoryId === undefined ? "active" : ""}
                  onClick={() => {
                    setCategoryQuery({ id: undefined });
                  }}
                >
                  <span className="checkmark" /> All Categories
                </button>
              </div>
            </li>
            {categories?.map((category, key) => {
              return (
                <li key={key}>
                  <div className="sidebar-widget-list-left">
                    <button
                      className={
                        activeCategoryId === category.id ? "active" : ""
                      }
                      onClick={() => {
                        setCategoryQuery({ id: category.id });
                      }}
                    >
                      <span className="checkmark" />
                      {category.name}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          "No categories found"
        )}
      </div>
    </div>
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func,
};

export default ShopCategories;
