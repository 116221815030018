import React from "react"
import styles from "./ModifyProductFormDetail.module.css"
import { Button, Form, Image, Input, Select, message } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { baseurl } from "../../utils/axios"
import { useMutation } from "react-query"
import { publishImage } from "../../Services/products"

function ModifyForm({
  data,
  size,
  selectedImageCol,
  generatedAllImages,
  imagine,
}) {
  const [show, setShow] = useState(false)
  const navigate = useNavigate()

  const handleOnChange = (value) => {
    if (value === "NEW") {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  const { mutate, isLoading } = useMutation((data) => publishImage({ data }), {
    onSuccess: (res) => {
      navigate(`/confirmation?productId=${res?.data.generatedProduct?.id}`)
    },
    onError: (err) => {
      message.error(err?.response?.data?.message)
    },
  })

  const onFinish = (value) => {
    const productDetail = {
      productName: value?.productName,
      productDescription: value?.productDescription,
      productSize: value?.productSize,
      price: parseFloat(value?.setPrice),
      collectionTitle: value?.collectiontitle,
      collectionDescription: value?.collectionDescription,
      categoryId: data?.isProduct?.categoryId,
      images: generatedAllImages,
      collectionId: value === "NEW" ? undefined : +value?.collectionId,
      imagine,
    }
    mutate(productDetail)
  }

  return (
    <div className={styles.parentDiv}>
      <div className={styles.modifyForm}>
        <Form className={` ${styles.modifyForm}`} onFinish={onFinish}>
          <div className={styles.item}>
            <span className={styles.prompt}>Product Name</span>
            <Form.Item
              disabled={isLoading}
              name="productName"
              initialValue={data?.isProduct?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter a product name!",
                },
              ]}
              className={`${styles.formItem} input`}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={styles.item}>
            <span className={styles.prompt}>Product Description</span>
            <Form.Item
              initialValue={data?.isProduct?.description}
              name="productDescription"
              rules={[
                {
                  required: true,
                  message: "Please enter a product description!",
                },
              ]}
              className={`${styles.formItem} input`}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={styles.fields}>
            <div className={styles.item}>
              <span className={styles.prompt}>Product Category</span>
              <Form.Item
                name="productCategory"
                initialValue={data?.isProduct?.Category?.name}
                rules={[
                  {
                    required: true,
                    message: "Please enter a product category!",
                  },
                ]}
                className={`${styles.formItem} input`}
              >
                <Input disabled />
              </Form.Item>
            </div>
            <div className={styles.item}>
              <span className={styles.prompt}>Product Size</span>
              <Form.Item
                name="productSize"
                initialValue={size}
                rules={[
                  {
                    required: true,
                    message: "Please enter a product size!",
                  },
                ]}
                className={`${styles.formItem} input`}
              >
                <Input disabled />
              </Form.Item>
            </div>
          </div>
          <div className={styles.item}>
            <span className={styles.prompt}>Collection</span>
            <Form.Item
              name="collectionId"
              rules={[
                {
                  required: true,
                  message: "Please enter a collection!",
                },
              ]}
              className={`${styles.formItem} input`}
            >
              <Select
                placeholder={"Choose a Collection"}
                onChange={handleOnChange}
                style={{
                  width: "100%",
                  height: "45px",
                }}
                options={[
                  {
                    value: "NEW",
                    label: "+ new collection",
                  },
                  ...(data?.collections?.map((item) => {
                    return {
                      label: item.title,
                      value: item.id,
                    }
                  }) || []),
                ]}
              />
            </Form.Item>
          </div>
          {show && (
            <div className={styles.item}>
              <span className={styles.prompt}>Collection Title</span>
              <Form.Item
                name="collectiontitle"
                rules={[
                  {
                    required: show === true ? true : false,
                    message: "Please enter a collection titel!",
                  },
                ]}
                className={`${styles.formItem} input`}
              >
                <Input />
              </Form.Item>
            </div>
          )}
          {show && (
            <div className={styles.item}>
              <span className={styles.prompt}>Collection Description</span>
              <Form.Item
                name="collectionDescription"
                rules={[
                  {
                    required: show === true ? true : false,
                    message: "Please enter a collection description!",
                  },
                ]}
                className={`${styles.formItem} input`}
              >
                <Input />
              </Form.Item>
            </div>
          )}
          <div className={styles.item}>
            <span className={styles.prompt}>Set Price</span>
            <Form.Item
              name="setPrice"
              initialValue={data?.isProduct?.price}
              rules={[
                {
                  required: true,
                  message: "Please enter a set price!",
                },
              ]}
              className={`${styles.formItem} input`}
            >
              <Input type="number" placeholder="$0.00" />
            </Form.Item>
          </div>
          {selectedImageCol && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textAlign: "left",
                  width: "100%",
                }}
                className={styles.prompt}
              >
                Preview <InfoCircleOutlined className={styles.infoIcon} />
              </span>
              <Form.Item name="preview" className={`${styles.formItem} input`}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div className={styles.gridGalleryImageParent}>
                    {selectedImageCol && (
                      <Image
                        alt="img"
                        src={`${baseurl}/${selectedImageCol}`}
                        preview={false}
                        className={styles.galleryImage}
                      />
                    )}
                  </div>
                </div>
              </Form.Item>
            </div>
          )}

          <Form.Item className={`${styles.formItem}`}>
            <div className={styles.button}>
              <Button
                disabled={isLoading}
                htmlType="submit"
                className={styles.btn}
              >
                {isLoading ? "PUBLISH...." : "PUBLISH"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ModifyForm
