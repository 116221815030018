import { Col, Image, Input, Row, Space, Spin, message } from "antd"
import React, { useEffect } from "react"
import { Button } from "react-bootstrap"
import styles from "./ModidfyProductDetail.module.css"
import { InfoCircleOutlined } from "@ant-design/icons"
import { useState } from "react"
import { useMutation } from "react-query"
import { manupulateImage } from "../../Services/products"
import { baseurl } from "../../utils/axios"
import { io } from "socket.io-client"
import { v4 as uuidv4 } from "uuid"
import { useLocation } from "react-router"

function Prompt({
  setSelectedImageCol,
  selectedImageCol,
  setGeneratedAllImages,
  imagine,
  setImagine,
}) {
  const [selectedImage, setSelectedImage] = useState(0)
  const [text, setText] = useState("")
  const [inputValue, setInputValue] = useState("")
  const [compositeImage, setCompositeImage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [socket, setSocket] = useState(null)
  const [substrateSets, setSubstrateSets] = useState(null)
  const [isFinalizing, setIsFinalizing] = useState(false)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const productId = searchParams.get("productId")

  useEffect(() => {
    const newSocket = io(baseurl)
    setSocket(newSocket)

    newSocket.on("connect", () => {
      newSocket.emit("joinRoom", uuidv4())
      // newSocket.emit("joinRoom",parsedData?.User?.id);
    })

    newSocket.on("generatedImage", (response) => {
      // Update the UI with the received message
      setCompositeImage({
        url: response?.attachments[0]?.url,
        blur: true,
      })
    })

    newSocket.on("generatedImageDone", (imgSrc) => {
      setIsFinalizing(true)
      setCompositeImage({
        url: imgSrc,
        blur: true,
      })
    })

    newSocket.on(
      "subsetsGenerated",
      ({ compositeImage, substrateSets: sets, imagine }) => {
        setSubstrateSets(sets)
        setIsLoading(false)
        setCompositeImage({
          url: compositeImage,
          blur: false,
        })
        setImagine({
          body: imagine,
          upscaleIndex: 0,
        })
      }
    )

    return () => {
      newSocket.disconnect()
    }
  }, [])

  const { mutate: manupulateImageMutate, isLoading: manupulateImageIsLoading } =
    useMutation((data) => manupulateImage(data), {
      onSuccess: (res) => {
        setSubstrateSets(res.substrateSets)
      },
      onError: (err) => {
        message.error(err)
      },
    })

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const textHandler = () => {
    manupulateImageMutate({
      compositeImage: compositeImage.url,
      productId,
      text,
    })
  }

  const handleSelectedImage = (image) => {
    setSelectedImageCol(image)
  }

  const generateImage = () => {
    setIsLoading(true)
    setCompositeImage(null)
    setSubstrateSets(null)
    socket.emit("prompt", { prompt: inputValue, productId })
  }

  useEffect(() => {
    if (substrateSets?.length > 0) {
      setGeneratedAllImages(substrateSets[selectedImage])
      setSelectedImageCol(substrateSets[selectedImage][0]?.url)
    }
  }, [setSelectedImageCol, substrateSets, selectedImage, setGeneratedAllImages])

  useEffect(() => {
    if (imagine) setImagine({ ...imagine, upscaleIndex: selectedImage })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage])

  return (
    <Spin
      spinning={manupulateImageIsLoading}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div>
        <div>
          <span className={styles.prompt}>
            Input Prompt <InfoCircleOutlined className={styles.infoIcon} />
          </span>
          <Space.Compact
            style={{
              width: "100%",
            }}
          >
            <Input
              type="text"
              onChange={(e) => handleInputChange(e)}
              className={`${styles.inputPrompt}`}
              disabled={isLoading}
            />
            <Button
              disabled={isLoading}
              onClick={generateImage}
              className={`${styles.btnGenerate} px-4`}
            >
              {isLoading ? "Generating..." : "Generate"}
            </Button>
          </Space.Compact>
        </div>

        {!compositeImage?.url && isLoading ? (
          <div className="d-flex flex-column justify-content-center align-items-center container pt-5">
            <Spin style={{ background: "transparent", boxShadow: "none" }} />
            <span style={{ color: "#5a9eff" }}>Waiting to Start...</span>
          </div>
        ) : (
          ""
        )}

        <div
          style={{ width: "100%", height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          {compositeImage?.url && (
            <Spin
              tip={
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {isLoading && isFinalizing
                    ? "Finalizing..."
                    : "Generating..."}
                </span>
              }
              style={{
                width: "100%",
                height: "100%",
                background: "transparent",
                boxShadow: "none",
              }}
              spinning={isLoading}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                }}
                className="mt-5"
              >
                <div
                  className="flex items-center justify-center w-full py-2 compositeImageParent"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    position: "relative",
                    cursor: "pointer",
                    maxWidth: "500px",
                    maxHeight: "500px",
                  }}
                >
                  <Image
                    preview={false}
                    src={compositeImage?.url + ".png"}
                    style={
                      compositeImage?.blur
                        ? {
                            filter: "blur(3px)",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            cursor: "pointer",
                          }
                        : {
                            maxWidth: "100%",
                            maxHeight: "100%",
                            cursor: "pointer",
                          }
                    }
                    loading="lazy"
                  />
                  <div
                    style={{
                      display: "flex ",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        width: "100%",
                        height: "50%",
                      }}
                    >
                      <div
                        onClick={() => setSelectedImage(0)}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignContent: "end",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            display: "flex ",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "20px ",
                            height: "20px",
                            border: "2px solid #fff",
                            marginRight: "10px",
                            marginTop: "10px",
                            borderRadius: "10px",

                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",

                              borderRadius: "10px",
                              background: selectedImage === 0 ? "#fff" : "",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        onClick={() => setSelectedImage(1)}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignContent: "end",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex ",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "20px ",
                            height: "20px",
                            border: "2px solid #fff",
                            marginRight: "10px",
                            marginTop: "10px",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",

                              borderRadius: "10px",
                              background: selectedImage === 1 ? "#fff" : "",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        width: "100%",
                        height: "50%",
                      }}
                    >
                      <div
                        onClick={() => setSelectedImage(2)}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignContent: "end",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex ",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "20px ",
                            height: "20px",
                            border: "2px solid #fff",
                            marginRight: "10px",
                            marginTop: "10px",
                            borderRadius: "10px",

                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",

                              borderRadius: "10px",
                              background: selectedImage === 2 ? "#fff" : "",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        onClick={() => setSelectedImage(3)}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignContent: "end",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex ",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "20px ",
                            height: "20px",
                            border: "2px solid #fff",
                            marginRight: "10px",
                            marginTop: "10px",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",

                              borderRadius: "10px",
                              background: selectedImage === 3 ? "#fff" : "",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 
              </div>
            </Spin>
          )}
        </div>
        <div className="mt-4">
          <span className={styles.prompt}>
            Text <InfoCircleOutlined className={styles.infoIcon} />
          </span>
          <Space.Compact
            style={{
              width: "100%",
            }}
            className="mt-1"
          >
            <Input
              disabled={isLoading || manupulateImageIsLoading}
              type="text"
              className={`${styles.inputPrompt}`}
              onChange={(e) => setText(e.target.value)}
            />

            <Button
              onClick={textHandler}
              className={`${styles.btnGenerate} px-4`}
              disabled={isLoading || manupulateImageIsLoading}
            >
              {manupulateImageIsLoading ? "Text..." : "Text"}
            </Button>
          </Space.Compact>
        </div>
        <div>
          {substrateSets?.length > 0 && (
            <div className="mt-4">
              <span className={styles.prompt}>
                Preview <InfoCircleOutlined className={styles.infoIcon} />
              </span>

              <div>
                <Row gutter={[8, 8]} className="mt-1">
                  <Col xxl={17} xl={17} lg={17} md={17} sm={17} xs={24}>
                    <div className={styles.gridGalleryImageParent}>
                      {selectedImageCol && (
                        <Image
                          alt=""
                          src={`${baseurl}/${selectedImageCol}`}
                          preview={false}
                          className={styles.galleryImage}
                        />
                      )}
                    </div>
                  </Col>
                  <Col xxl={7} xl={7} lg={7} md={7} sm={7} xs={24}>
                    <div className={styles.colImageContainer}>
                      {substrateSets[selectedImage]?.map((gridImage) => {
                        return (
                          <div
                            className={styles.colImageParent}
                            key={gridImage.id}
                          >
                            <Image
                              alt=""
                              src={`${baseurl}/${gridImage.url}`}
                              preview={false}
                              className={styles.colImage}
                              onClick={() => handleSelectedImage(gridImage.url)}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      </div>
    </Spin>
  )
}

export default Prompt
