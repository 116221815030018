import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";

const MobileNavMenu = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem("artefax");
    const parsedData = JSON.parse(userData);
    setUserData(parsedData?.User);
  }, []);

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li>
          <Link to={process.env.PUBLIC_URL + "/"}>{t("home")}</Link>
        </li>

        <li
          style={{
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: 500,
            padding: "10px 0px",
          }}
        >
          {userData && userData.role === "CREATOR" && (
            <Link to={process.env.PUBLIC_URL + "/creator"}>
              {t("Products")}
            </Link>
          )}
        </li>
        <li
          style={{
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: 500,
            padding: "10px 0px",
          }}
        >
          {/* <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}> */}
          {t("About")}
          {/* </Link> */}
        </li>

        <li
          style={{
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: 500,
            padding: "10px 0px",
          }}
        >
          {/* <Link to={process.env.PUBLIC_URL + "/contact"}> */}
          {t("contact_us")}
          {/* </Link> */}
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
