import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import RelatedProductSlider from "../../wrappers/product/RelatedProductSlider";
import ProductImageDescription from "../../wrappers/product/ProductImageDescription";
import { useQuery } from "react-query";
import { getGeneratedProductById } from "../../Services/products";

const GeneratedProduct = () => {
  const { id } = useParams();
  const [productId, setProductId] = useState();

  const { data, isLoading } = useQuery(
    ["getGeneratedProductById", productId],
    () => getGeneratedProductById({ productId: +productId }),
    {
      enabled: !!productId,
    }
  );

  useEffect(() => {
    setProductId(id);
  }, [id]);

  return (
    <Fragment>
      <SEO
        titleTemplate="Product By Id"
        description="Product Page of flone react minimalist eCommerce template."
      />

      <LayoutOne headerTop="visible">
        <Breadcrumb
          pages={[{ label: "Products", path: process.env.PUBLIC_URL + "/" }]}
        />

        <ProductImageDescription
          spaceTopClass="pt-100"
          spaceBottomClass="pb-100"
          product={data?.product}
          isLoading={isLoading}
        />

        <RelatedProductSlider
          spaceBottomClass="pb-95"
          category={data?.relatedProducts}
          isLoading={isLoading}
        />
      </LayoutOne>
    </Fragment>
  );
};

export default GeneratedProduct;
