import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useEffect, useState } from "react";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem("artefax");
    const parsedData = JSON.parse(userData);
    setUserData(parsedData?.User);
  }, []);

  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              {t("home")}
              {/* {sidebarMenu ? (
                <span>
                  {" "}
                  <i className="fa fa-angle-right"></i>{" "}
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )} */}
            </Link>
          </li>
          <li
            style={{
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: 500,
              color: "#555252",
            }}
          >
            {userData && userData.role === "CREATOR" && (
              <Link to={process.env.PUBLIC_URL + "/creator"}>
                {t("Products")}
              </Link>
            )}
          </li>

          <li
            style={{
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: 500,
              color: "#555252",
            }}
          >
            {/* <Link to={process.env.PUBLIC_URL + "/blog-standard"}> */}
            {t("About")}
            {/* {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )} */}
            {/* </Link> */}
          </li>
          <li
            style={{
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: 500,
              color: "#555252",
            }}
          >
            {/* <Link to={process.env.PUBLIC_URL + "/contact"}> */}
            {t("contact_us")}
            {/* </Link> */}
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
