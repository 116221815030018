import { axios } from "../utils/axios";

export const getAllProducts = async ({ productId }) => {
  return await axios
    .get(`/product/getByProductId/${productId}`)
    .then((res) => res.data);
};

export const getProductById = async ({ productId }) => {
  return await axios
    .get(`/product/single-product/${productId}`)
    .then((res) => res.data);
};

export const getGeneratedProductById = async ({ productId }) => {
  return await axios
    .get(`/generate-product/${productId}`)
    .then((res) => res.data);
};

export const generateImages = async (value) => {
  return await axios
    .post(`/generate-image`, { prompt: value })
    .then((res) => res.data);
};

export const manupulateImage = async (data) => {
  return await axios
    .post(`/generate-image/manipulate-image`, data)
    .then((res) => res.data);
};

export const publishImage = async ({ data }) => {
  return await axios.post(`/generate-product`, data);
};

export const publishProduct = async ({ id }) => {
  return await axios.get(`/generate-product/${id}`).then((res) => res.data)
}
export const upscale = async (data) => {
  return await axios
    .post(`/generate-image/upscale`, data)
    .then((res) => res.data);
};
