import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const Logo = ({ imageUrl, logoClass }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem("artefax");
    const parsedData = JSON.parse(userData);
    setUserData(parsedData?.User);
  }, []);

  return (
    <div className={clsx(logoClass)}>
      <Link
        to={
          userData && userData?.role === "CREATOR"
            ? process.env.PUBLIC_URL + "/creator"
            : process.env.PUBLIC_URL + "/"
        }
      >
        <img alt="" src={process.env.PUBLIC_URL + imageUrl} />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
};

export default Logo;
