import { axios } from "../utils/axios";

export const getAllCategories = async () => {
  return await axios.get(`/category`).then((res) => res.data);
};

export const getAllAndGetById = async ({ categoryId, take, skip }) => {
  const params = {
    skip,
    take,
    categoryId,
  };
  return await axios
    .get(`/product/getByCategoryId`, { params })
    .then((res) => res.data);
};

export const getAllgeneratedProduct = async ({ categoryId, take, skip }) => {
  const params = {
    skip,
    take,
    categoryId,
  };
  return await axios
    .get(`/generate-product`, { params })
    .then((res) => res.data);
};
