// import PropTypes from "prop-types"
import clsx from "clsx"
import Swiper, { SwiperSlide } from "../../components/swiper"
import SectionTitle from "../../components/section-title/SectionTitle"
import ProductGridSingle from "../../components/product/ProductGridSingle"
import { Empty } from "antd"

const settings = {
  loop: false,
  slidesPerView: 4,
  grabCursor: true,
  spaceBetween: 30,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 4,
    },
  },
}

const RelatedProductSlider = ({
  spaceBottomClass,
  category,
  isLoading,
  creator = false,
}) => {
  return (
    <div className={clsx("related-product-area", spaceBottomClass)}>
      <div className="container">
        <SectionTitle
          titleText="Related Products"
          positionClass="text-center"
          spaceClass="mb-50"
        />
        {category?.length ? (
          <Swiper options={settings}>
            {category?.map((product) => (
              <SwiperSlide key={product.id}>
                <ProductGridSingle
                  product={product}
                  currency={product.price}
                  isLoading={isLoading}
                  creator={creator}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Empty description="No Related Products Found!" />
        )}
      </div>
    </div>
  )
}

// RelatedProductSlider.propTypes = {
//   category: PropTypes.string,
//   spaceBottomClass: PropTypes.string,
//   creator: PropTypes.bool,
//   isLoading: PropTypes.bool,
// };

export default RelatedProductSlider
