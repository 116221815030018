import React, { useState } from "react";
import styles from "./Confiramation.module.css";
import { Image, Result } from "antd";

function ConfiramtionComponent({ data }) {
  const [buttonText, setButtonText] = useState("Copy Link");

  const copyLink = () => {
    const linkElement = document.querySelector(".inputUrl");
    const linkText = linkElement.textContent;

    // Copy the link to the clipboard
    navigator.clipboard
      .writeText(linkText)
      .then(() => {
        setButtonText("Link Copied");
        setTimeout(() => setButtonText("Copy Link"), 3000); // Reset button text after 3 seconds
      })
      .catch((error) => console.error("Could not copy link", error));
  };

  return (
    <div className={styles.parentDiv}>
      <div className={styles.result}>
        <Result
          icon={
            <Image
              src="/assets/img/result.png"
              alt="success img"
              preview={false}
              style={{ height: "200px", width: "200px", objectFit: "cover" }}
            />
          }
          status="success"
          title="Congratulations!"
          subTitle="Ut enim ad minima veniam, quis nostrum exercitationem ullam
          corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
          consequatur? Quis autem vel eum iure reprehenderit qui in ea
          voluptate velit esse quam nihil molestiae consequatur."
        />
      </div>
      <div className={styles.preview}>
        <div className={styles.previewFirstDiv}>
          VIEW YOUR PUBLISHED PRODUCT DETAILS
        </div>
        <div className={styles.previewSecondDiv}>
          <div className={styles.urlCopyInput}>
            <div>URL</div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className={`${styles.inputurl} inputUrl`}
                style={{ overflow: "hidden", whiteSpace: "nowrap" }}
              >
                {data?.product?.directAccessUrl}
              </div>
              <div className={styles.copybtn} onClick={copyLink}>
                {buttonText}
              </div>
            </div>
          </div>
          <div className={styles.productDetail}>
            <div className={styles.productDetailsImage}>
              <div style={{ width: "100%" }}>
                <Image
                  src={data?.product?.images[0]}
                  alt="img"
                  preview={true}
                  style={{ height: "340px", width: "100%", objectFit: "cover" }}
                />
              </div>
            </div>

            <div
              className={styles.information}
              style={{ borderBottom: "2px solid #ebebeb", width: "100%" }}
            >
              <div className={styles.title}>{data?.product?.name}</div>
              <div className={styles.price}>${data?.product?.price}</div>
              <div className={styles.Content}>{data?.product?.description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfiramtionComponent;
