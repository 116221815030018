import React from "react";
import LayoutOne from "../../layouts/LayoutOne";
import BreadcrumbWrap from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
import ConfiramtionComponent from "../../components/ConfirmationComponent/ConfiramtionComponent";
import { publishProduct } from "../../Services/products";
import { useQuery } from "react-query";
import { Spin } from "antd";

function Confirmation() {
  let { pathname } = useLocation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");

  const { data } = useQuery(["getGenerateProduct"], () =>
    publishProduct({ id: productId })
  );
  return (
    <>
      <LayoutOne headerTop="visible">
        <BreadcrumbWrap
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "Confirmation",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        {data ? (
          <ConfiramtionComponent data={data} />
        ) : (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </LayoutOne>
    </>
  );
}

export default Confirmation;
