import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Swiper, { SwiperSlide } from "../../components/swiper";
import { baseurl } from "../../utils/axios";
import { Empty } from "antd";

const ProductImageGallery = ({ product, creator = false }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const thumbnailSwiperParams = {
    spaceBetween: 10,
    slidesPerView: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
    navigation: true,
  };

  useEffect(() => {
    if ((!creator && product?.images?.length !== 0) || product?.File?.length) {
      setSelectedImage(
        !creator ? product?.images[0] : `${baseurl}/${product?.File[0]?.url}`
      );
    }
  }, [creator, product]);

  return (
    <Fragment>
      <div
        className="product-large-image-wrapper"
        style={{ maxHeight: "600px" }}
      >
        {(!creator && product?.images?.length !== 0) ||
        product?.File?.length ? (
          <div className="single-image" style={{ border: "1px solid #F0F0F0" }}>
            <img
              src={selectedImage}
              className="img-fluid"
              alt=""
              style={{
                maxHeight: "600px",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        ) : (
          <Empty description="No Image Found!" />
        )}
      </div>
      <div className="product-small-image-wrapper mt-15">
        {(!creator && product?.images?.length !== 0) ||
        product?.File?.length ? (
          <Swiper options={thumbnailSwiperParams}>
            {(!creator ? product?.images : product?.File)?.map(
              (single, key) => (
                <SwiperSlide key={key}>
                  <div
                    onClick={() =>
                      setSelectedImage(
                        !creator ? single : `${baseurl}/${single.url}`
                      )
                    }
                    className="single-image"
                  >
                    <img
                      src={!creator ? single : `${baseurl}/${single.url}`}
                      className="img-fluid"
                      alt=""
                      style={{
                        height: "120px",
                        width: "100%",
                        objectFit: "cover",
                        border: "1px solid #F0F0F0",
                      }}
                    />
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

ProductImageGallery.propTypes = {
  product: PropTypes.shape({}),
  creator: PropTypes.bool,
};

export default ProductImageGallery;
