import React from "react"
import LayoutOne from "../../layouts/LayoutOne"
import BreadcrumbWrap from "../../wrappers/breadcrumb/Breadcrumb"
import { useLocation } from "react-router-dom"
import ModifyProductDetail from "../../components/ModifyProductDetail/ModifyProductDetail"
import { useQuery } from "react-query"
import { getAllProducts } from "../../Services/products"
import { Spin } from "antd"

function ModidfyPorduct() {
  let { pathname } = useLocation()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const productId = searchParams.get("productId")
  const size = searchParams.get("size")

  const { data, isLoading } = useQuery(
    ["getAllProducts"],
    () => getAllProducts({ productId }),
    {
      enabled: !!productId,
    }
  )

  return (
    <Spin
      spinning={isLoading}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <LayoutOne headerTop="visible">
        <BreadcrumbWrap
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "Modify Detail",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <ModifyProductDetail data={data} size={size} />
      </LayoutOne>
    </Spin>
  )
}

export default ModidfyPorduct
