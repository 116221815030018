import { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSortedProducts } from "../../helpers/product";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ShopSidebar from "../../wrappers/product/ShopSidebar";
import ShopTopbar from "../../wrappers/product/ShopTopbar";
import ShopProducts from "../../wrappers/product/ShopProducts";
import { useQuery } from "react-query";
import {
  getAllCategories,
  getAllgeneratedProduct,
} from "../../Services/categories";
import { Empty, Spin } from "antd";

const ShopCustomer = () => {
  const [category, setCategory] = useState();
  const [layout, setLayout] = useState("grid three-column");
  const [sortType, setSortType] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [filterSortType, setFilterSortType] = useState("");
  const [filterSortValue, setFilterSortValue] = useState("");
  const [offset, setOffset] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const { products } = useSelector((state) => state.product);

  const pageLimit = 15;

  const getLayout = (layout) => {
    setLayout(layout);
  };

  const getSortParams = (sortType, sortValue) => {
    setSortType(sortType);
    setSortValue(sortValue);
  };

  const getFilterSortParams = (sortType, sortValue) => {
    setFilterSortType(sortType);
    setFilterSortValue(sortValue);
  };

  useEffect(() => {
    let sortedProducts = getSortedProducts(products, sortType, sortValue);
    const filterSortedProducts = getSortedProducts(
      sortedProducts,
      filterSortType,
      filterSortValue
    );
    sortedProducts = filterSortedProducts;
    setSortedProducts(sortedProducts);
    setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
  }, [offset, products, sortType, sortValue, filterSortType, filterSortValue]);

  const { data: getcategory, isLoading: getCateogoryLoading } = useQuery(
    ["getAllCategories"],
    () => getAllCategories()
  );

  const { data, isLoading } = useQuery(
    ["getAllgeneratedProducts", category],
    () => getAllgeneratedProduct({ categoryId: category })
  );

  return (
    <Fragment>
      <SEO
        titleTemplate="Products"
        description="Products with all the categories."
      />

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[{ label: "PRODUCTS", path: process.env.PUBLIC_URL + "/" }]}
        />

        <div className="shop-area pt-95 pb-100">
          <div className="container" style={{ minHeight: "60vh" }}>
            {data ? (
              <Spin
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
                spinning={isLoading}
              >
                <div className="row">
                  <div className="col-lg-3 order-2 order-lg-1">
                    {/* shop sidebar */}
                    <ShopSidebar
                      products={getcategory}
                      getSortParams={getSortParams}
                      sideSpaceClass="mr-30"
                      isLoading={getCateogoryLoading}
                      setCategory={setCategory}
                    />
                  </div>
                  <div className="col-lg-9 order-1 order-lg-2">
                    {/* shop topbar default */}
                    <ShopTopbar
                      getLayout={getLayout}
                      getFilterSortParams={getFilterSortParams}
                      productCount={products.length}
                      sortedProductCount={currentData.length}
                    />

                    {/* shop page content default */}
                    {data && (
                      <ShopProducts
                        layout={layout}
                        products={category ? data : data?.products}
                        isLoading={isLoading}
                      />
                    )}
                    {/* shop product pagination */}
                    {/* <div className="pro-pagination-style text-center mt-30">
                  <Paginator
                    totalRecords={sortedProducts.length}
                    pageLimit={pageLimit}
                    pageNeighbours={2}
                    setOffset={setOffset}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageContainerClass="mb-0 mt-0"
                    pagePrevText="«"
                    pageNextText="»"
                  />
                </div> */}
                  </div>
                </div>
              </Spin>
            ) : (
              <Empty description="No product Found!" />
            )}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ShopCustomer;
