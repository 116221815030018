import { message } from "antd"
import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"

const ProductDescriptionInfo = ({ product, creator = false }) => {
  const navigate = useNavigate()
  const [size, setSize] = useState()

  const price = +product?.price

  const [consumer] = useState(true)

  const sizeHandle = (value) => {
    setSize(value)
  }

  return (
    <div className="product-details-content ml-70">
      <h2>{product?.name}</h2>
      {!isNaN(price) && (
        <div className="product-details-price">
          <span>${" " + price} </span>
        </div>
      )}
      <div className="pro-details-list">
        <p>{product?.description}</p>
      </div>
      {consumer ? (
        <Fragment>
          {product ? (
            <div className="pro-details-size-color">
              <div className="pro-details-size">
                <span>Size</span>
                {product?.size && !Array.isArray(product?.size) ? (
                  <div
                    style={{ padding: "10px" }}
                    className={`pro-details-size-content--single`}
                  >
                    {product.size}
                  </div>
                ) : (
                  <div className="pro-details-size-content">
                    {product?.size &&
                      product?.size?.map((single) => {
                        return (
                          <div
                            className={`pro-details-size-content--single`}
                            key={single}
                          >
                            <div
                              value={single}
                              onClick={() => sizeHandle(single)}
                            />
                            <span
                              onClick={() => sizeHandle(single)}
                              className="size-name"
                              style={{
                                backgroundColor:
                                  size === single ? "black" : "#eee",
                                color: size === single ? "white" : "black",
                              }}
                            >
                              {single}
                            </span>
                          </div>
                        )
                      })}
                  </div>
                )}
                <div className="pro-details-quality">
                  <div className="pro-details-cart btn-hover">
                    {!creator ? (
                      <button>PURCHASE</button>
                    ) : (
                      <button
                        onClick={() =>
                          size
                            ? navigate(
                                `/modify-product-detail?productId=${product?.id}&size=${size}`
                              )
                            : message.error(" Please choose size")
                        }
                      >
                        START DESIGNING
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Fragment>
      ) : (
        <div className="pro-details-size-color">
          <div className="pro-details-size">
            <span>Size</span>
            <div className="pro-details-size-content">
              {product?.size &&
                product?.size?.map((single) => {
                  return (
                    <label
                      className={`pro-details-size-content--single`}
                      key={single}
                    >
                      <input type="radio" value={single} />
                      <span className="size-name">{single}</span>
                    </label>
                  )
                })}
            </div>
            <div className="pro-details-quality">
              <div className="pro-details-cart btn-hover">
                <button onClick={() => navigate("/modify-product-detail")}>
                  PURCHASE
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

ProductDescriptionInfo.propTypes = {
  product: PropTypes.shape({}),
  creator: PropTypes.bool,
}

export default ProductDescriptionInfo
