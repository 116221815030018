import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children, roles }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("artefax");
    const user = JSON.parse(userData);

    if (!roles.includes(user?.User?.role)) {
      navigate("/");
    }
  }, []);

  return children;
};
