import Axios from "axios";

export const baseurl = process.env.REACT_APP_BASE_URL;

const Token = async () => {
  try {
    const authData = localStorage.getItem("artefax");
    const parsedData = JSON.parse(authData);
    return parsedData?.token;
  } catch (err) {}
};

export const axios = Axios.create({
  baseURL: baseurl,
});

axios.interceptors.request.use(async function (config) {
  config.headers = {
    "auth-token": await Token(),
  };
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("artefax");

      if (error?.response?.data?.message !== "Invalid credentials!") {
        window.location.replace("/login");
      }
    }
    throw error;
  }
);
